import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import InputMask from "react-input-mask";
import Lottie from "react-lottie";
// import { gtag } from "ga-gtag";
import { messages } from "consts";
import { Slider, RightPageContainer, Stages, Email } from "components";
import { yup, api, currency, masks } from "lib";
import { useApiGet, useQuery } from "hooks";
import { MdChevronRight } from "react-icons/md";
import animationDataMoneySmile from "assets/lottie/money-smile.json";
import animationDataRenewableEnergy from "assets/lottie/renewable-energy.json";

const events = {
  fazendacervejeira: "64b99957a27f084e9df426d6",
  "black-da-click": "654bbf1db761b56d6df90c64",
};

const partners = {
  fazendacervejeira: "64c18854b79548805ae46117",
};

export const SignUpGeneral = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = useMemo(() => {
    const [__, pathname] = location.pathname.split("/");
    return pathname;
  }, [location.pathname]);
  const query = useQuery();
  const [data] = useApiGet(useMemo(() => ({ path: `/public/leads/${_id}`, options: { isEnabled: !!_id } }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const isLoadingData = useMemo(() => {
    if (_id) return !data;
    return false;
  }, [_id, data]);
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenAlreadyExists, onOpen: onOpenAlreadyExists, onClose: onCloseAlreadyExists } = useDisclosure();
  const { isOpen: isOpenAdditionUc, onOpen: onOpenAdditionalUc, onClose: onCloseAdditionalUc } = useDisclosure();
  const { isOpen: isOpenSpeekConsultant, onOpen: onOpenSpeekConsultant, onClose: onCloseSpeekConsultant } = useDisclosure();
  const toast = useToast();
  const pageTitle = useMemo(() => {
    switch (pathname) {
      case "fazendacervejeira":
        return "Fazenda Cervejeira";
      case "black-da-click":
        return "Black da Click";
      default:
        return "Quero Economizar";
    }
  }, [pathname]);
  const imgs = useMemo(() => {
    switch (pathname) {
      case "fazendacervejeira":
        return {
          bg: require("assets/imgs/sign-up/fazendacervejeira/bg-01.png"),
          fg: require("assets/imgs/sign-up/fazendacervejeira/fg-01.png"),
        };
      case "black-da-click":
        return {
          bg: require("assets/imgs/sign-up/black-da-click/bg-01.jpg"),
          fg: require("assets/imgs/sign-up/black-da-click/fg-01.png"),
        };
      default:
        return {
          bg: require("assets/imgs/sign-up/bg-01.jpg"),
          fg: require("assets/imgs/sign-up/fg-01.png"),
        };
    }
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   gtag("event", "page_view", {
  //     page_title: pageTitle,
  //     page_location: location.pathname,
  //   });
  // }, []);

  const handleNavigateAlreadyExists = useCallback(
    (data) => {
      const pathName = (() => {
        switch (data.level) {
          case 1:
            return `/${pathname}/endereco/${data._id}`;
          case 2:
            return `/${pathname}/documentacao/${data._id}`;
          case 3:
            return `/${pathname}/sucesso`;
          default:
            return `/${pathname}/inicio/${data._id}`;
        }
      })();
      navigate(pathName, { replace: true });
    },
    [pathname]
  );

  useEffect(() => {
    const formData = data ?? {
      type: "pf",
      consumerUnits: [{ electricityBillAmount: 0 }],
      exhibition: events?.[pathname] ?? query.evento,
      indicatedBy: query.indicacao,
      level: 0,
      platform: "website",
      author: partners?.[pathname],
      ...location.state,
    };
    if (process.env.NODE_ENV === "development") {
      formData.name = "João das Neves";
      formData.phone = "37999999999";
      formData.email = "joaodasneves@gmail.com";
    }
    if (formData._id) handleNavigateAlreadyExists(formData);
    setFormData(formData);
  }, [data, location.state, query.indicacao, pathname, handleNavigateAlreadyExists]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const response = await api.put(`/public/leads`, data);
        setFormData(response);
        if (response.alreadyExists) {
          setIsLoadingSaveData(false);
          if (response.level <= 2) onOpenAlreadyExists();
          else onOpenAdditionalUc();
        } else if (pathname === "black-da-click") onOpenSpeekConsultant();
        else navigate(`/${pathname}/endereco/${response._id}`);
      } catch (error) {
        toast({ description: error.message, status: "error", duration: 5000, isClosable: true });
        setIsLoadingSaveData(false);
      }
    },
    [pathname, onOpenAlreadyExists, onOpenSpeekConsultant, toast]
  );

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const consumerUnitSchema = yup.object().shape({
          electricityBillAmount: yup.number().min(1, messages.error.required).required(messages.error.required),
        });
        const shape = {
          name: yup.string().isValidName(messages.error.invalidName).required(messages.error.required),
          phone: yup.string().required(messages.error.required),
          consumerUnits: yup.array().of(consumerUnitSchema).min(1, messages.error.required),
        };
        if (pathname !== "black-da-click") shape.email = yup.string().email(messages.error.invalidEmail).required(messages.error.required);
        const schema = yup.object().shape(shape);
        await schema.validate(formData, { abortEarly: false });
        handleSaveData(formData);
        setFormErrors({});
      } catch (error) {
        const formErrors = {};
        for (const { path, message } of error.inner) _.set(formErrors, path, message);
        setFormErrors(formErrors);
      }
    },
    [pathname, formData, handleSaveData]
  );

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <RightPageContainer bgSrc={imgs.bg} fgSrc={imgs.fg} isLoading={isLoadingData}>
        <Stages activeIndex={0} size={3} />
        <VStack flex="1" alignItems="stretch" justifyContent="center" spacing="15px" py="20px">
          <FormControl isRequired={true} isInvalid={formErrors.name}>
            <FormLabel fontSize="sm">Nome completo</FormLabel>
            <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
            <FormErrorMessage>{formErrors.name}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={formErrors.phone}>
            <FormLabel fontSize="sm">Celular</FormLabel>
            <Input
              as={InputMask}
              inputMode="numeric"
              mask="(99) 99999-9999"
              value={formData.phone ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, phone: target.value }))}
            />
            <FormErrorMessage>{formErrors.phone}</FormErrorMessage>
          </FormControl>
          {pathname !== "black-da-click" && (
            <FormControl isRequired={true} isInvalid={formErrors.email}>
              <FormLabel fontSize="sm">E-mail</FormLabel>
              <Email
                value={formData.email ?? ""}
                onChange={(email) => setFormData((state) => ({ ...state, email }))}
                isInvalid={formErrors.email}
              />
              <FormErrorMessage>{formErrors.email}</FormErrorMessage>
            </FormControl>
          )}
          {_.map(
            formData.consumerUnits,
            (consumerUnit, index) =>
              index === 0 && (
                <FormControl pt="5px" isRequired={true} isInvalid={formErrors.consumerUnits?.[index]?.electricityBillAmount}>
                  <HStack mb="5px" justifyContent="space-between">
                    <Box flex="1">
                      <FormLabel mb="0" fontSize="sm">
                        Valor da conta
                      </FormLabel>
                      <Text fontSize="xs">Arraste a barra abaixo até o valor aproximado da sua conta de luz.</Text>
                    </Box>
                    <Heading size="sm">{currency.format(consumerUnit.electricityBillAmount)}</Heading>
                  </HStack>
                  <Slider
                    value={consumerUnit.electricityBillAmount}
                    onChange={(electricityBillAmount) =>
                      setFormData((state) => {
                        const consumerUnits = [...state.consumerUnits];
                        consumerUnits[index].electricityBillAmount = electricityBillAmount;
                        return { ...state, consumerUnits };
                      })
                    }
                  />
                  <FormErrorMessage>{formErrors.consumerUnits?.[index]?.electricityBillAmount}</FormErrorMessage>
                </FormControl>
              )
          )}
        </VStack>
        <HStack>
          <Box flex="1" />
          <Button
            size="sm"
            colorScheme="main"
            rightIcon={<Icon as={MdChevronRight} />}
            isLoading={isLoadingSaveData}
            onClick={handleSubmit}
          >
            continuar
          </Button>
        </HStack>
      </RightPageContainer>

      <Modal isOpen={isOpenSpeekConsultant} onClose={onCloseSpeekConsultant} isCentered>
        <ModalOverlay>
          <ModalContent>
            <ModalBody>
              <VStack spacing="0" p="30px">
                <Heading size="lg" textAlign="center">
                  Olá {formData.name}!
                </Heading>
                <Text fontSize="sm" textAlign="center">
                  Deseja continuar e finalizar seu pré cadastro sozinho ou prefere falar com um de nossos especialistas em desconto?
                </Text>
                <Box h="20px" />
                <Button w="100%" onClick={() => navigate(`/${pathname}/endereco/${formData._id}`)}>
                  continuar
                </Button>
                <Box h="5px" />
                <Button w="100%" colorScheme="main" onClick={() => navigate(`/${pathname}/sucesso`)}>
                  falar com um especialista
                </Button>
              </VStack>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>

      <Modal isOpen={isOpenAlreadyExists} onClose={onCloseAlreadyExists} isCentered>
        <ModalOverlay>
          <ModalContent>
            <ModalBody>
              <VStack spacing="0" p="30px" pt="0">
                <Lottie
                  options={{ loop: true, autoplay: true, animationData: animationDataMoneySmile }}
                  isClickToPauseDisabled={true}
                  width={250}
                  height={250}
                  style={{ cursor: "default" }}
                />
                <Heading size="lg" textAlign="center">
                  Bem-vindo de volta!
                </Heading>
                <Text fontSize="sm" textAlign="center">
                  Que bom ter você de volta, vamos continuar de onde parou! Encontramos um cadastro prévio associado ao celular{" "}
                  <strong>{masks.phone(formData?.phone)}</strong>. Bora lá finalizar seu cadastro e fazer parte do nosso time de
                  economizadores.
                </Text>
                <Box h="20px" />
                <HStack>
                  <Button variant="ghost" onClick={onCloseAlreadyExists}>
                    Cancelar
                  </Button>
                  <Button colorScheme="yellow" onClick={() => handleNavigateAlreadyExists(formData)}>
                    Vamos lá
                  </Button>
                </HStack>
              </VStack>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>

      <Modal isOpen={isOpenAdditionUc} onClose={onCloseAdditionalUc} isCentered>
        <ModalOverlay>
          <ModalContent>
            <ModalBody>
              <VStack spacing="0" p="30px" pt="0">
                <Lottie
                  options={{ loop: true, autoplay: true, animationData: animationDataRenewableEnergy }}
                  isClickToPauseDisabled={true}
                  width={250}
                  height={250}
                  style={{ cursor: "default" }}
                />
                <Heading size="lg" textAlign="center">
                  Bem-vindo de volta!
                </Heading>
                <Text fontSize="sm" textAlign="center">
                  Que bom ter você de volta! Encontramos um cadastro prévio associado ao celular{" "}
                  <strong>{masks.phone(formData?.phone)}</strong>. Você deseja cadastrar uma nova Unidade Consumidora com o mesmo titular?
                </Text>
                <Box h="20px" />
                <Button
                  w="100%"
                  colorScheme="main"
                  onClick={() => navigate(`/${pathname}/documentacao/${formData._id}`, { state: { isAdditionalUc: true } })}
                >
                  Nova Unidade Consumidora
                </Button>
                <Box h="5px" />
                <Button variant="ghost" w="100%" onClick={onCloseAdditionalUc}>
                  Cancelar
                </Button>
              </VStack>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};
