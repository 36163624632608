import React from "react";
import { Box, Center, Heading, Image, SimpleGrid, Text, VStack } from "@chakra-ui/react";

const ComoFunciona = () => {
  return (
    <Center id="como-funciona" bg="#FCFCFC">
      <Box maxW="1300px" w="90%" py="100px">
        <Heading color="main.500" size="2xl" textAlign="center" mb="60px">
          Como funciona
        </Heading>
        <SimpleGrid columns={[1, 3]} spacing="100px">
          <VStack pt={{ base: "0", lg: "100px" }} position="relative">
            <Image
              src={require("assets/imgs/como-funciona/seta-1.png")}
              w="200px"
              position="absolute"
              top="50px"
              right="-100px"
              display={{ base: "none", lg: "block" }}
            />
            <Image src={require("assets/imgs/como-funciona/placa-e-sol.png")} />
            <Text fontSize="lg" color="gray.600" textAlign="center">
              Nossas fazendas solares produzem energia limpa e renovável.
            </Text>
          </VStack>
          <VStack position="relative">
            <Image src={require("assets/imgs/como-funciona/torre-transmissao.png")} />
            <Text fontSize="lg" color="gray.600" textAlign="center">
              Essa energia é enviada para a rede de distribuição da CEMIG.
            </Text>
            <Image
              src={require("assets/imgs/como-funciona/seta-2.png")}
              w="200px"
              position="absolute"
              bottom="20px"
              right="-100px"
              display={{ base: "none", lg: "block" }}
            />
          </VStack>
          <VStack pt={{ base: "0", lg: "100px" }}>
            <Image src={require("assets/imgs/como-funciona/casa.png")} />
            <Text fontSize="lg" color="gray.600" textAlign="center">
              E chega em sua casa em forma de créditos de energia, que se transformam em desconto na sua conta.
            </Text>
          </VStack>
        </SimpleGrid>
      </Box>
    </Center>
  );
};

export default ComoFunciona;
