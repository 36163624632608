import React, { useEffect, useState } from "react";
import { Center, Image as ChakraImage, SlideFade, Spinner, Grid, GridItem, Box, VStack } from "@chakra-ui/react";
import _ from "lodash";

export const RightPageContainer = ({ bgSrc, fgSrc, isLoading, children }) => {
  const [isImagesReady, setIsImagesReady] = useState(false);

  useEffect(() => {
    (async () => {
      const promises = [bgSrc, fgSrc].map((src) => {
        return new Promise(function (resolve, reject) {
          const img = new Image();
          img.src = src;
          img.onload = resolve();
          img.onerror = reject();
        });
      });
      await Promise.all(promises);
      setIsImagesReady(true);
    })();
  }, [bgSrc, fgSrc]);

  return (
    <Box bgImage={bgSrc} bgSize="auto 100%">
      <Grid m="0 auto" maxW="1440px" templateColumns="repeat(12, 1fr)">
        <GridItem colSpan={{ base: 12, lg: 8 }} h="100%" display="flex" flexDirection="row" alignItems="flex-end" px="20px">
          {isImagesReady ? (
            <SlideFade in={true} offsetY="40px">
              <ChakraImage src={fgSrc} maxHeight="650px" />
            </SlideFade>
          ) : (
            <Center p="30px" h="100%" w="100%">
              <Spinner />
            </Center>
          )}
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }} p="20px" pt={{ base: "0", lg: "20px" }}>
          <VStack minH="100%" align="stretch" borderWidth="1px" borderRadius="lg" p="30px" bg="white">
            {isLoading ? (
              <Center flex="1">
                <Spinner />
              </Center>
            ) : (
              children
            )}
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
};
