import React, { useMemo, useState } from "react";
import { Box, Center, HStack, Heading, Icon, Spinner, StackDivider, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { IoAddOutline, IoRemoveOutline } from "react-icons/io5";
import { useApiGet } from "hooks";

const Duvidas = () => {
  const [data, isLoadingData] = useApiGet(useMemo(() => ({ path: "/public/faq" }), []));
  const [expanded, setExpanded] = useState({});

  return (
    <Center id="perguntas-frequentes" bg="#FCFCFC">
      <Box maxW="1300px" w="90%" py="100px">
        <Heading color="main.500" size="2xl" textAlign="center" mb="60px">
          Ficou alguma dúvida?
        </Heading>
        {isLoadingData && (
          <Center p="40px">
            <Spinner color="main.500" />
          </Center>
        )}
        <VStack alignItems="stretch" divider={<StackDivider />}>
          {_.map(data, (item) => (
            <Box key={item._id} w="100%">
              <HStack
                py="20px"
                cursor="pointer"
                color={expanded[item._id] ? "main.500" : "secondary.500"}
                onClick={() => setExpanded((state) => ({ ...state, [item._id]: !state[item._id] }))}
              >
                <Text fontSize="xl" fontWeight="600" flex="1">
                  {item.title}
                </Text>
                <Icon as={expanded[item._id] ? IoRemoveOutline : IoAddOutline} boxSize="30px" />
              </HStack>
              {expanded[item._id] && (
                <Text color="gray.600" my="10px">
                  {item.content}
                </Text>
              )}
            </Box>
          ))}
        </VStack>
      </Box>
    </Center>
  );
};

export default Duvidas;
