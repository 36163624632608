import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Button, Heading, HStack, Icon, Input, Text, VStack } from "@chakra-ui/react";
import { MdCheck } from "react-icons/md";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { RightPageContainer } from "components";
import Lottie from "react-lottie";
import animationData from "assets/lottie/share.json";

const shareTitle =
  "Quer ter uma conta de luz *mais barata*? Com a *ClickLivre* você pode! Sou cliente e estou recebendo *desconto na minha conta de luz todo mês*! Clica aí no meu link para *garantir a sua economia*:";

export const IndicationsShare = () => {
  const location = useLocation();
  const shareUrl = useMemo(() => `${process.env.REACT_APP_BASE_URL}/indicacoes/${location.state._id}`, [location.state._id]);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(shareUrl);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  }, [shareUrl]);

  return (
    <>
      <Helmet>
        <title>Seu endereço de compartilhamento</title>
      </Helmet>
      <RightPageContainer bgSrc="linear-gradient(to right, #00513E, #00462A, #002D13)" fgSrc={require("assets/imgs/sign-up/fg-04.png")}>
        <VStack flex="1" alignItems="stretch" justifyContent="center" spacing="15px" py="20px">
          <Lottie
            options={{ loop: true, autoplay: true, animationData }}
            isClickToPauseDisabled={true}
            width={200}
            height={200}
            style={{ cursor: "default" }}
          />
          <Heading size="md">Prontinho!</Heading>
          <Text fontSize="sm">
            Seu endereço de compartilhamento está aqui. Escolha a maneira que deseja compartilhar ou então clique em copiar para enviar da
            maneira que quiser.
          </Text>
          <HStack mt="20px">
            <Input value={shareUrl} isDisabled={true} />
            <Button colorScheme="main" rightIcon={isCopied && <Icon as={MdCheck} />} onClick={handleCopyToClipboard}>
              {isCopied ? "Copiado" : "Copiar"}
            </Button>
          </HStack>
          <Heading mt="20px" mb="10px" size="sm">
            Compartilhe também pelas redes sociais:
          </Heading>
          <HStack>
            <EmailShareButton url={shareUrl}>
              <EmailIcon size={32} round={true} title={shareTitle} />
            </EmailShareButton>
            <FacebookShareButton url={shareUrl} title={shareTitle}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} title={shareTitle}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <WhatsappShareButton url={shareUrl} title={shareTitle}>
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
          </HStack>
        </VStack>
      </RightPageContainer>
    </>
  );
};
