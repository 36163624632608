const { useState, useEffect } = require("react");
const { useLocation, useNavigationType } = require("react-router-dom");

export const useHistoryStack = () => {
  const [stack, setStack] = useState([]);
  const { pathname } = useLocation();
  const type = useNavigationType();

  useEffect(() => {
    if (type === "POP") {
      setStack(stack.slice(0, stack.length - 1));
    } else if (type === "PUSH") {
      setStack([...stack, pathname]);
    } else {
      setStack([...stack.slice(0, stack.length - 1), pathname]);
    }
  }, [pathname, type]);

  return stack;
};
