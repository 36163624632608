import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, SimpleGrid, Image, Button, HStack, VStack, Link, ListItem, List, ListIcon, Text } from "@chakra-ui/react";
import _ from "lodash";
import { HiOutlineCheckCircle } from "react-icons/hi";
import ClickClubLogo from "assets/svgs/click-club";

const ClickClub = () => {
  return (
    <SimpleGrid id="click-club" columns={{ base: 1, lg: 2 }} bg="main.500">
      <Box w="100%">
        <Image w="100%" src={require("assets/imgs/click-club/bg.jpg")} />
      </Box>
      <VStack p={{ base: "40px 20px", lg: "0 0 0 60px" }} w={{ base: "100%", lg: "650px" }} justifyContent="center" alignItems="stretch">
        <Box maxW="400px">
          <ClickClubLogo width="100%" />
        </Box>
        <Text fontSize="2xl" color="white">
          O clube de descontos exclusivo para clientes ClickLivre energia chegou!
        </Text>
        <Box h="20px" />
        <Text color="white" fontSize="lg" pb="10px">
          É fácil participar:
        </Text>
        <List fontSize="lg" color="white" spacing="15px">
          <ListItem>
            <ListIcon as={HiOutlineCheckCircle} position="relative" top="-4px" />
            Você indica a ClickLivre para seus amigos e para empresas.
          </ListItem>
          <ListItem>
            <ListIcon as={HiOutlineCheckCircle} position="relative" top="-4px" />A sua indicação contrata a ClickLivre.
          </ListItem>
          <ListItem>
            <ListIcon as={HiOutlineCheckCircle} position="relative" top="-4px" />
            Você ganha R$50,00 de desconto na sua próxima fatura ClickLivre.
          </ListItem>
        </List>
        <Box h="20px" />
        <HStack>
          <Button as={RouterLink} to="/indique-e-ganhe" size="lg" colorScheme="secondary">
            INDIQUE E GANHE
          </Button>
        </HStack>
      </VStack>
    </SimpleGrid>
  );
};

export default ClickClub;
