import React, { useMemo } from "react";
import { Slider as ChakraUiSlider, SliderFilledTrack, SliderThumb, SliderTrack } from "@chakra-ui/react";

export const Slider = ({ sliderTrackBg, value, onChange }) => {
  const step = useMemo(() => {
    if (value > 5000) return 200;
    if (value > 1000) return 100;
    return 50;
  }, [value]);

  return (
    <ChakraUiSlider size="lg" colorScheme="main" value={value} max={10000} step={step} onChange={onChange}>
      <SliderTrack h="20px" borderRadius="full" bg={sliderTrackBg}>
        <SliderFilledTrack bg="secondary.400" />
      </SliderTrack>
      <SliderThumb bg="secondary.500" w="30px" h="30px" />
    </ChakraUiSlider>
  );
};
