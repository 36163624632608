import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
// import { gtag } from "ga-gtag";
import Banners from "./Banners";
import Calculadora from "./Calculadora";
import QuemSomos from "./QuemSomos";
import ClickClub from "./ClickClub";
import Downloads from "./Downloads";
import ComoFunciona from "./ComoFunciona";
import Depoimentos from "./Depoimentos";
import Duvidas from "./Duvidas";

export const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   gtag("event", "page_view", {
  //     page_title: "Página inicial",
  //     page_location: "/",
  //   });
  // }, []);

  return (
    <>
      <Helmet>
        <title>ClickLivre | E economize na sua conta de energia.</title>
      </Helmet>
      <Banners />
      <Calculadora />
      <ComoFunciona />
      <ClickClub />
      <Depoimentos />
      <QuemSomos />
      <Duvidas />
      <Downloads />
    </>
  );
};
