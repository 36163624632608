import { extendTheme, defineStyleConfig } from "@chakra-ui/react";

const Link = defineStyleConfig({
  baseStyle: {
    textDecoration: "none",
    _hover: {
      color: "main.500",
      textDecoration: "none",
    },
  },
});

export const theme = extendTheme({
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Poppins', sans-serif`,
  },
  colors: {
    main: {
      50: "#bfe8c4",
      100: "#aae0b0",
      200: "#95d99c",
      300: "#80d188",
      400: "#55c161",
      500: "#33a437",
      600: "#228e2e",
      700: "#1a6b22",
      800: "#16591d",
      900: "#114717",
    },
    secondary: {
      50: "#81a59d",
      100: "#689289",
      200: "#4f8075",
      300: "#356e61",
      400: "#1c5c4e",
      500: "#034a3a",
      600: "#034334",
      700: "#023b2e",
      800: "#023429",
      900: "#022c23",
    },
  },
  components: {
    Link,
  },
});
