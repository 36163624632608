import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useQuery = () => {
  const location = useLocation();
  return useMemo(() => {
    const query = new URLSearchParams(location.search);
    const response = {};
    for (const key of query.keys()) response[key] = query.get(key);
    return response;
  }, [location.search]);
};
