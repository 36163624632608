import React, { useCallback, useState } from "react";
import { Box, Center, Heading, Image, SimpleGrid, Text, Link, VStack, IconButton, Icon } from "@chakra-ui/react";
import { TbCloudDownload, TbLink } from "react-icons/tb";

const DownloadButton = ({ href, isLink = false }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = useCallback(() => {
    setIsLoading(true);
    setTimeout(() => {
      window.open(href);
      setIsLoading(false);
    }, 1000);
  }, [href]);

  return (
    <IconButton
      icon={<Icon as={isLink ? TbLink : TbCloudDownload} boxSize="30px" />}
      w="60px"
      h="60px"
      mb="10px"
      colorScheme="secondary"
      isRound
      isLoading={isLoading}
      onClick={handleDownload}
    />
  );
};

const Downloads = () => {
  return (
    <Center id="downloads" bg="main.500" position="relative">
      <Box maxW="1300px" w="90%" py="100px" position="relative" zIndex="1">
        <Heading color="white" size="2xl" textAlign="center" mb="80px">
          Downloads
        </Heading>

        <SimpleGrid columns={[1, 4]} spacing="40px" mb="80px">
          <VStack>
            <DownloadButton href="/downloads/REN-1059-ANEEL.pdf" />
            <Text color="white" fontSize="xl" textAlign="center">
              Resolução Aneel 1.059
            </Text>
          </VStack>
          <VStack>
            <DownloadButton href="/downloads/REN-1.000-ANEEL.pdf" />
            <Text color="white" fontSize="xl" textAlign="center">
              Resolução Aneel 1.000
            </Text>
          </VStack>
          <VStack>
            <DownloadButton href="/downloads/01-Caderno-tematico-Micro-e-Minigeracao-Distribuida-2-edicao-ANEEL.pdf" />
            <Text color="white" fontSize="xl" textAlign="center">
              Caderno temático Micro e Minigeração Distribuida | Aneel
            </Text>
          </VStack>
          <VStack>
            <DownloadButton href="/downloads/ACD-0001-21_MiniGeracao_Distribuida-CEMIG.pdf" />
            <Text color="white" fontSize="xl" textAlign="center">
              Cartilha Micro e Minigeração Distribuida | Cemig
            </Text>
          </VStack>
        </SimpleGrid>

        <SimpleGrid columns={[1, 4]} spacing="40px">
          <VStack>
            <DownloadButton href="/downloads/Lei-14.300-Marco-Legal-da-GD.pdf" />
            <Text color="white" fontSize="xl" textAlign="center">
              Lei 14.300 Marco Legal da GD
            </Text>
          </VStack>
          <VStack>
            <DownloadButton href="/downloads/Contrato-Consorcio_Carvalho_de_Energia_Solar.pdf" />
            <Text color="white" fontSize="xl" textAlign="center">
              Contrato - Consórcio Carvalho de Energia Solar
            </Text>
          </VStack>
          <VStack>
            <DownloadButton href="https://www.cemig.com.br/cemig-atende/" isLink={true} />
            <Text color="white" fontSize="xl" textAlign="center">
              Site Cemig Atende
            </Text>
          </VStack>
          <VStack>
            <DownloadButton href="https://www.gov.br/aneel/pt-br/assuntos/geracao-distribuida" isLink={true} />
            <Text color="white" fontSize="xl" textAlign="center">
              Site Geração Distribuída | Aneel
            </Text>
          </VStack>
        </SimpleGrid>
      </Box>
    </Center>
  );
};

export default Downloads;
