import { Flex, Text, useColorModeValue, useToken } from "@chakra-ui/react";
import React, { useMemo } from "react";
import Autocomplete from "react-autocomplete";

const providers = ["@gmail.com", "@hotmail.com", "@yahoo.com", "@outlook.com", "@icloud.com"];

export const Email = ({ value, onChange, isInvalid }) => {
  const [backgroundColor, borderColor, borderColorInvalid] = useToken("colors", [
    useColorModeValue("white", "gray.800"),
    useColorModeValue("gray.200", "whiteAlpha.300"),
    useColorModeValue("red.500", "red.300"),
  ]);
  const items = useMemo(() => {
    if (value.length === 0) return [];
    const options = providers.map((provider) => {
      const [name] = value.split("@");
      const item = { value: name.concat(provider) };
      item.remaining = item.value.replace(value, "");
      return item;
    });
    if (value.indexOf("@") !== -1) return options.filter((o) => o.value.indexOf(value) !== -1);
    return options;
  }, [value]);

  return (
    <Autocomplete
      getItemValue={(item) => item.value}
      items={items}
      renderItem={(item, isHighlighted) => (
        <Flex
          key={item.value}
          _light={{ bg: isHighlighted ? "gray.100" : "white" }}
          _dark={{ bg: isHighlighted ? "gray.900" : "gray.800" }}
          px="15px"
          py="10px"
        >
          <Text fontSize="sm" fontWeight="semibold">
            {value}
          </Text>
          <Text fontSize="sm">{item.remaining}</Text>
        </Flex>
      )}
      value={value}
      onChange={({ target }) => onChange(target.value.toLowerCase())}
      onSelect={(value) => onChange(value.toLowerCase())}
      menuStyle={{
        borderRadius: "4px",
        boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
        backgroundColor,
        position: "fixed",
        overflow: "auto",
        maxHeight: "50%",
        zIndex: "9999",
      }}
      inputProps={{
        style: {
          width: "100%",
          backgroundColor,
          border: isInvalid ? "2px solid" : "1px solid",
          borderColor: isInvalid ? borderColorInvalid : borderColor,
          padding: "0 15px",
          height: "40px",
          borderRadius: "6px",
        },
      }}
      wrapperStyle={{ width: "100%" }}
    />
  );
};
