import React, { useState } from "react";
import { Box, HStack, Image, useMediaQuery } from "@chakra-ui/react";
import _ from "lodash";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const items = [
  {
    desktop: require("assets/banners/img-01-desktop.jpg"),
    mobile: require("assets/banners/img-01-mobile.jpg"),
  },
];

const Banners = () => {
  const [isMobileView] = useMediaQuery("(max-width: 940px)");
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Box id="home" position="relative">
      <Carousel
        selectedItem={activeIndex}
        onChange={(index) => setActiveIndex(index)}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        emulateTouch={true}
        infiniteLoop={true}
      >
        {items.map((item, index) => (
          <div key={index.toString()}>
            <Image src={isMobileView ? item.mobile : item.desktop} />
          </div>
        ))}
      </Carousel>
      {_.size(items) > 1 && (
        <HStack justifyContent="center" position="absolute" bottom="160px" w="100%">
          {items.map((item, index) => (
            <Box
              key={index.toString()}
              w="10px"
              h="10px"
              borderRadius="full"
              bg="white"
              cursor="pointer"
              opacity={index === activeIndex ? "1" : "0.5"}
              onClick={() => setActiveIndex(index)}
            />
          ))}
        </HStack>
      )}
    </Box>
  );
};

export default Banners;
