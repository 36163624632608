import React, { useState } from "react";
import { Box, Center, HStack, Heading, Text } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const items = [
  {
    text: "Toda a economia que fiz durante esse tempo por causa da redução da conta de luz, me ajudou a investir em algumas máquinas aqui na padaria. Por causa disso consegui aumentar a produção e meu faturamento. O pessoal da ClickLivre foi muito atencioso e rápido no atendimento e na hora de dar o desconto na minha conta. Já tem outros comércios aqui perto que recomendei e estão na ClickLivre, inclusive na minha casa já tem ClickLivre.",
    author: "Marcelo de Castro – Cliente ClickLivre",
  },
  {
    text: "Foi tudo muito prático. Mandei meu contato no site e no mesmo dia um representante me ligou. Forneci alguns dados específicos da minha conta atual. Foi muito rápido. Fiz o cadastro em janeiro e em março já estava recebendo o boleto com desconto de 12% na minha conta. A conta já mostra tudo sobre o desconto, quanto eu tenho de crédito e quanto sobrou do mês passado. Fácil de entender. Recomento para todos que eu conheço!",
    author: "Lidiane Alves – Cliente ClickLivre",
  },
];

const Depoimentos = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <Center id="depoimentos" bg="#FCFCFC">
      <Box maxW="1300px" w="90%" py="100px">
        <Heading color="main.500" size="2xl" textAlign="center" mb="40px">
          O que nossos clientes falam sobre nós
        </Heading>
        <Carousel
          selectedItem={activeIndex}
          onChange={(index) => setActiveIndex(index)}
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          emulateTouch={true}
        >
          {items.map((item, index) => (
            <div key={index.toString()}>
              <Text fontSize="lg" color="gray.600" mb="20px">
                {item.text}
              </Text>
              <Text fontSize="sm" color="gray.600">
                {item.author}
              </Text>
            </div>
          ))}
        </Carousel>
        <HStack justifyContent="center">
          {items.map((item, index) => (
            <Box
              key={index.toString()}
              w="10px"
              h="10px"
              borderRadius="full"
              bg="secondary.500"
              cursor="pointer"
              opacity={index === activeIndex ? "1" : "0.5"}
              onClick={() => setActiveIndex(index)}
            />
          ))}
        </HStack>
      </Box>
    </Center>
  );
};

export default Depoimentos;
