import React, { Fragment } from "react";
import { Box, Center, HStack } from "@chakra-ui/react";
import _ from "lodash";

export const Stages = ({ activeIndex, size }) => {
  return (
    <HStack spacing="10px">
      {_.range(0, size).map((index) => (
        <Fragment key={index.toString()}>
          <Center
            w="30px"
            h="30px"
            borderRadius="full"
            bg={index <= activeIndex ? "main.500" : "gray.100"}
            color={index <= activeIndex ? "white" : "gray.500"}
            fontSize="xs"
            fontWeight="bold"
          >
            {index + 1}
          </Center>
          {index < size - 1 && <Box flex="1" bg={index < activeIndex ? "main.400" : "gray.100"} borderRadius="full" h="10px" />}
        </Fragment>
      ))}
    </HStack>
  );
};
