import React, { useContext, useMemo } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Link } from "@chakra-ui/react";
import { HeaderContext } from "./index";

const MenuItem = ({ children, ...props }) => {
  const { isMinimized, isMobileView } = useContext(HeaderContext);

  return (
    <Link
      fontSize={isMobileView ? "lg" : isMinimized ? "xs" : "sm"}
      p="10px"
      fontWeight="600"
      transition="200ms"
      color="secondary.500"
      _hover={{ color: "main.500", textDecoration: "none" }}
      {...props}
    >
      {children}
    </Link>
  );
};

const Menu = () => {
  const location = useLocation();
  const pathname = useMemo(() => {
    const [__, pathname] = location.pathname.split("/");
    return pathname;
  }, [location.pathname]);

  if (pathname === "fazendacervejeira") return <MenuItem href="/">Conheça a ClickLivre</MenuItem>;

  return (
    <>
      <MenuItem href="/">Início</MenuItem>
      <MenuItem
        as={RouterLink}
        to="/quero-economizar"
        color="white"
        bg="main.500"
        py="2px"
        px="10px"
        borderRadius="xl"
        _hover={{ bg: "secondary.500" }}
      >
        Quero economizar
      </MenuItem>
      <MenuItem as={HashLink} to="/#click-club">
        Click Club
      </MenuItem>
      <MenuItem as={RouterLink} to="/indique-e-ganhe">
        Indique e ganhe
      </MenuItem>
      <MenuItem href="https://dash.clicklivre.com.br">Área do parceiro</MenuItem>
      <MenuItem
        href="https://cliente.clicklivre.com.br"
        py="2px"
        px="10px"
        borderRadius="xl"
        color="white"
        bg="secondary.500"
        _hover={{ bg: "secondary.700" }}
      >
        Área do cliente
      </MenuItem>
    </>
  );
};

export default Menu;
